import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Grid,
  Divider,
} from "@material-ui/core";
import { Card, CardContent } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core/styles";
import { media_url } from "../constants/Data";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import AlertDialog from "../components/AlertDialogStatic.js";
import frame1 from "../assets/staticPages/frame1.png";
// import independent from "../assets/staticPages/independent.png";
// import practice from "../assets/staticPages/practice.png";
// import immersive from "../assets/staticPages/immersive.png";
// import services from "../assets/staticPages/services.png";
// import damJourney from "../assets/staticPages/damJourney.png";
import quote from "../assets/staticPages/quote.png";
import damQues from "../assets/staticPages/damQues.png";

const useStyles = makeStyles((theme) => ({
  root: {
    //  padding:theme.spacing(8),
    backgroundColor: "white",
    minWidth: "none",
    "&.MuiContainer-root": {
      maxWidth: 1821, //1440,
    },
  },
  headerBox: {
    height: "694px",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "70px",
    justifyContent: "space-around",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      height: "388px",
    },
    "@media (max-width: 800px)": {
      height: "193px",
      paddingBottom: "20px",
    },
  },
  header: {
    fontSize: 96,
    fontWeight: 600,
    fontStyle: "italic",
    color: "#021CA5",
    [theme.breakpoints.down("md")]: {
      fontSize: 56,
      paddingLeft: 20,
    },
    "@media (max-width: 800px)": {
      fontSize: 26,
      paddingLeft: 20,
    },
  },
  headerText: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.4,
    width: "1000px",
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    "@media (max-width: 800px)": {
      fontSize: 16,
    },
  },
  sectionTitle: {
    color: "#1E0E62",
    fontSize: 48,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 38,
    },
    "@media (max-width: 800px)": {
      fontSize: 30,
    },
  },
  sectionContent: {
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    "@media (max-width: 800px)": {
      margin: theme.spacing(2, 0),
      fontSize: 14,
    },
  },
  sectionHeading: {
    margin: theme.spacing(1, 4),
    display: "flex",
    //flexDirection: "column",
    justifyContent: "space-around", //"space-between",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0),
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      margin: theme.spacing(0, 0),
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  section: {
    margin: theme.spacing(1, 4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around", //"space-between",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0),
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      margin: theme.spacing(0, 0),
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  sectionText: {
    //  margin: theme.spacing(6, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 800px)": {
      margin: theme.spacing(2, 0),
      textAlign: "center",
    },
  },
  button: {
    width: "200px",
    height: "60px",
    fontSize: "18px",
    textTransform: "none",
    backgroundColor: "#F29F05",
    color: "white",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#021CA5",
    },
    "@media (max-width: 800px)": {
      width: "106px",
      height: "34px",
      fontSize: "8px",
    },
  },
  quoteBox: {
    paddingTop: 80,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    //  alignItems:"center",
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (max-width: 800px)": {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  quoteText: {
    padding: theme.spacing(6, 1),
    color: "white",
    lineHeight: 1.5,
    fontSize: 28,
    letterSpacing: "1px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(6, 1),
      fontSize: 22,
    },
    "@media (max-width: 800px)": {
      padding: theme.spacing(4, 1),
      fontSize: 14,
    },
  },
  quoteTextPer: {
    paddingLeft: theme.spacing(4),
    fontSize: 22,
    letterSpacing: "1px",
    color: "white",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(4),
      fontSize: 18,
    },
    "@media (max-width: 800px)": {
      paddingLeft: theme.spacing(2),
      fontSize: 10,
    },
  },
  quoteQuoteT: {
    paddingRight: theme.spacing(6), //theme.spacing(3, 6, 9, 9),
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(2),
    fontSize: 120,
    color: "white",
    display: "flex",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(8),
      fontSize: 90,
    },
    "@media (max-width: 800px)": {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      fontSize: 60,
    },
  },
  quoteQuoteB: {
    paddingRight: theme.spacing(9),
    paddingLeft: theme.spacing(6),
    fontSize: 120,
    color: "white",
    display: "flex",
    alignItems: "end",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(9),
      paddingLeft: theme.spacing(6),
      fontSize: 90,
    },
    "@media (max-width: 800px)": {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      fontSize: 60,
    },
  },
  quote: {
    display: "flex",
    justifyContent: "center",
  },
  quoteImg: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
    //width: "80%",
    // "@media (max-width: 1280px)": {
    //   width: 700,
    // },
  },
  card: {
    transition: "transform 0.3s ease-in-out, z-index 0s ease-in-out 0.3s",
    cursor: "pointer",
    position: "relative",
    border: "1px solid #021CA5",
    borderRadius: "16px",
    margin: theme.spacing(0, 0.5),
    "@media (max-width: 800px)": {
      marginRight: 2,
    },
  },
  activeCard: {
    transform: "scale(1.1)",
    zIndex: 1,
    position: "relative",
  },
  cardText: {
    fontSize: 22,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    "@media (max-width: 800px)": {
      fontSize: 6,
    },
  },
  cardCompany: {
    padding: theme.spacing(4, 0),
    paddingBottom: 10,
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    "@media (max-width: 800px)": {
      fontSize: 7,
      padding: theme.spacing(2, 0),
      paddingBottom: 5,
    },
  },
  addCircle: {
    marginTop: "20px",
    backgroundColor: "#021CA5",
    color: "#fff",
    border: "1px solid #021CA5",
    borderRadius: "100%",
    width: 35,
    height: 35,
    [theme.breakpoints.down("md")]: {
      width: 20,
      height: 20,
    },
    "@media (max-width: 800px)": {
      marginRight: "-10px",
      marginTop: "12px",
      transform: "scale(0.4)",
    },
    "&:hover": {
      backgroundColor: "#021CA5",
      color: "#fff",
    },
  },

  damQuesSection: {
    //margin: theme.spacing(1, 0),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0),
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      margin: theme.spacing(0, 0),
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  damQuesBox: {
    height: "430px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "30px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    [theme.breakpoints.down("md")]: {
      height: "375px",
    },
    "@media (max-width: 800px)": {
      height: "158px",
      paddingBottom: "20px",
    },
  },
  historySection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0),
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      margin: theme.spacing(0, 0),
    },
  },
  historyTitle: {
    paddingBottom: 20,
    color: "#021CA5",
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    "@media (max-width: 800px)": {
      fontSize: 18,
    },
  },
  historyContent: {
    margin: theme.spacing(0, 6),
    lineHeight: 1.3,
    fontSize: 22,
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    height: 60,
    [theme.breakpoints.down("md")]: {
      height: 55,
      fontSize: 18,
    },
    "@media (max-width: 800px)": {
      height: 40,
      fontSize: 12,
    },
  },
  historyBox: {
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    height: "120px",
    border: "1px solid #021CA5",
    borderRadius: "16px",
    padding: "24px",
    marginTop: "10px",
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //  justifyContent: "space-between",
    width: "95%",
    [theme.breakpoints.down("md")]: {
      height: "100px",
      width: "92%",
    },
    "@media (max-width: 800px)": {
      height: "90px",
      width: "88%",
    },
  },
  historyYear: {
    cursor: "pointer",
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    "@media (max-width: 800px)": {
      fontSize: 10,
    },
  },
  arrowIcon: {
    backgroundColor: "#fff",
    color: "#0D47A1",
    border: "1px solid #021CA5",
    borderRadius: "100%",
    width: 56,
    height: 56,
    [theme.breakpoints.down("md")]: {
      width: 48,
      height: 48,
    },
    "@media (max-width: 800px)": {
      width: 24,
      height: 24,
    },
    "&:hover": {
      backgroundColor: "#021CA5",
      color: "#fff",
      //border: "none",
    },
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedYear, setSelectedYear] = useState("2022");
  const [openStoryDlg, setOpenStoryDlg] = useState([]);
  const historyData = {
    2022: "Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.",
    2023: "Another significant event happened this year. You can add more details here.",
    2024: "Yet another notable occurrence. Feel free to include whatever text you need.", // Add more years and their corresponding text here
  };

  const mediaPath = `/${media_url}/`;

  const cards = [
    {
      title: "Startup Framework",
      shortText:
        "Startup Framework gives you complete freedom over your creative process.",
      longText:
        "Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.",
      companyName: "Company Name",
    },
    {
      title: "Startup Framework",
      shortText:
        "Startup Framework gives you complete freedom over your creative process.",
      longText:
        "Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.",
      companyName: "Company Name",
    },
    {
      title: "Startup Framework",
      shortText:
        "Startup Framework gives you complete freedom over your creative process.",
      longText:
        "Startup Framework gives you complete freedom over your creative process — you don’t have to think about any technical aspects. There are no limits and absolutely no coding.",
      companyName: "Company Name",
    },
  ];
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isTablet =
  //   useMediaQuery(theme.breakpoints.between('sm', 'md'));
  //
  // const isDesc = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(`(max-width:800px)`);
  const isTablet = useMediaQuery(`(min-width:801px) and (max-width:1399px)`);

  const isDesc = useMediaQuery(theme.breakpoints.up("md"));

  console.log("isMobileSm___________", isMobile);
  console.log("isTablet___________", isTablet);
  console.log("isDesc___________", isDesc);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <Container className={classes.root}>
      <Box
        className={classes.headerBox}
        sx={{
          backgroundImage: `url(${frame1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h3" className={classes.header}>
          We take care, y'hear?
        </Typography>
        <Grid
          container
          spacing={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            style={{ display: "flex", justifyContent: "center", width: "80%" }}
          >
            <Typography className={classes.headerText}>
              Startup Framework gives you complete freedom over your creative
              process — you don’t have to think about any technical aspects.
              There are no limits and absolutely no coding.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {isMobile && (
        <>
          <Typography
            variant="h4"
            className={classes.sectionTitle}
            style={{ textAlign: "center", paddingTop: 40 }}
          >
            We are independent
          </Typography>
          <Box
            pt={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={mediaPath + "independent.png"} width={170} height={170} />
          </Box>
          <Box pr={0} pb={2} className={classes.sectionText}>
            <Box pt={1} pb={2}>
              <Typography variant="body1" className={classes.sectionContent}>
                We are a vendor-neutral, vendor-agnostic,
                <br /> product-neutral, product-agnostic practice.
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button className={classes.button}>Read More</Button>
            </Box>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box pt={8} pb={4}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2} />
            <Grid
              item
              xs={7}
              sm={6}
              md={5}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="h4" className={classes.sectionTitle}>
                We are independent
              </Typography>
              <Box pr={2} className={classes.sectionText}>
                <Box pt={3} pb={5}>
                  <Typography
                    variant="body1"
                    className={classes.sectionContent}
                  >
                    We are a vendor-neutral, vendor-agnostic,
                    <br /> product-neutral, product-agnostic practice.
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button className={classes.button}>Read More</Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              sm={4}
              md={3}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ height: 83 }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={mediaPath + "independent.png"}
                  width={isTablet ? 224 : 314}
                  height={isTablet ? 224 : 314}
                />
              </Box>
            </Grid>
            <Grid item xs={1} sm={3} md={2} />
          </Grid>
        </Box>
      )}

      {isMobile && (
        <>
          <Typography
            variant="h4"
            className={classes.sectionTitle}
            style={{ textAlign: "center", paddingTop: 40 }}
          >
            We are a practice
          </Typography>
          <Box
            pt={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={mediaPath + "practice.png"} width={170} height={170} />
          </Box>
          <Box pr={0} pb={2} className={classes.sectionText}>
            <Box pt={1} pb={2}>
              <Typography variant="body1" className={classes.sectionContent}>
                We draw a parallel with doctors, lawyers, and craft persons in
                terms of their professions. What they have in common is they
                have a practice. We also operate as a practice in the area of
                DAM.
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button className={classes.button}>Read More</Button>
            </Box>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box pt={8} pb={4}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2} />
            <Grid
              item
              xs={3}
              sm={4}
              md={3}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ height: 83 }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  src={mediaPath + "practice.png"}
                  width={isTablet ? 224 : 314}
                  height={isTablet ? 224 : 314}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={7}
              sm={6}
              md={5}
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: isTablet ? "8%" : "11%",
              }}
            >
              <Typography variant="h4" className={classes.sectionTitle}>
                We are a practice
              </Typography>
              <Box pr={2} className={classes.sectionText}>
                <Box pt={3} pb={3}>
                  <Typography
                    variant="body1"
                    className={classes.sectionContent}
                  >
                    We draw a parallel with doctors, lawyers, and craft persons
                    in
                    <br /> terms of their professions. What they have in common
                    is they
                    <br /> have a practice.
                    <br /> We also operate as a practice in the area of DAM.
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button className={classes.button}>Read More</Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={1} sm={3} md={2} />
          </Grid>
        </Box>
      )}

      <Box className={classes.quoteBox}>
        <Box
          sx={{
            backgroundImage: `url(${quote})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "16px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography variant="h1" className={classes.quoteQuoteT}>
              <i>“</i>
            </Typography>

            <Typography variant="h1" className={classes.quoteText}>
              <i>
                It's through the team… bumping up against each other… and
                working together... they polish each other and polish the ideas.
                And what comes out are these really beautiful stones.
              </i>

              <i>
                <span className={classes.quoteTextPer}>-- Steve Jobs</span>
              </i>
            </Typography>

            <Typography variant="h1" className={classes.quoteQuoteB}>
              <i>”</i>
            </Typography>
          </Box>
        </Box>
      </Box>

      {isMobile && (
        <>
          <Typography
            variant="h4"
            className={classes.sectionTitle}
            style={{ textAlign: "center", paddingTop: 40 }}
          >
            Immersive Experience
          </Typography>
          <Box
            pt={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={mediaPath + "immersive.png"} width={170} height={170} />
          </Box>
          <Box pr={0} pb={2} className={classes.sectionText}>
            <Box pt={1} pb={2}>
              <Typography variant="body1" className={classes.sectionContent}>
                We opt in for an immersive experience. This means that we work
                the details with the DAM practitioners in your organization in a
                real-life and real-time learning journey. We accomplish this by
                executing our proprietary DAM playbook with you and your team
                members  as active contributors. This also means no webinars and
                certainly certification classes.
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button className={classes.button}>Read More</Button>
            </Box>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box pt={8} pb={4}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2} />
            <Grid
              item
              xs={7}
              sm={6}
              md={5}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h4" className={classes.sectionTitle}>
                Immersive Experience
              </Typography>
              <Box pr={2} className={classes.sectionText}>
                <Box pt={3} pb={5}>
                  <Typography
                    variant="body1"
                    className={classes.sectionContent}
                    style={{ marginRight: isTablet && "30px" }}
                  >
                    We opt in for an immersive experience. This means that we{" "}
                    <br />
                    work the details with the DAM practitioners in your
                    organization
                    <br /> in a real-life and real-time learning journey. <br />
                    We accomplish this by executing our proprietary DAM playbook
                    <br /> with you and your team members  as active
                    contributors.
                    <br /> This also means no webinars and certainly
                    certification classes.
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button className={classes.button}>Read More</Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              sm={4}
              md={3}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ height: 83 }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={mediaPath + "immersive.png"}
                  width={isTablet ? 224 : 314}
                  height={isTablet ? 224 : 314}
                />
              </Box>
            </Grid>
            <Grid item xs={1} sm={3} md={2} />
          </Grid>
        </Box>
      )}

      {isMobile && (
        <>
          <Typography
            variant="h4"
            className={classes.sectionTitle}
            style={{ textAlign: "center", paddingTop: 40 }}
          >
            Data Services
          </Typography>
          <Box
            pt={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={mediaPath + "services.png"} width={170} height={170} />
          </Box>
          <Box pr={0} pb={2} className={classes.sectionText}>
            <Box pt={1} pb={2}>
              <Typography variant="body1" className={classes.sectionContent}>
                We get our fingers dirty in the data mapping trenches by
                migrating your assets to the DAM system. We design the metadata
                structure and we build the org-wide taxonomy from the ground up.
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button className={classes.button}>Read More</Button>
            </Box>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box pt={8} pb={4}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2} />
            <Grid
              item
              xs={3}
              sm={4}
              md={3}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ height: 83 }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  src={mediaPath + "services.png"}
                  width={isTablet ? 224 : 314}
                  height={isTablet ? 224 : 314}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={7}
              sm={6}
              md={5}
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: isTablet ? "8%" : "11%",
              }}
            >
              <Typography variant="h4" className={classes.sectionTitle}>
                Data Services
              </Typography>
              <Box pr={2} className={classes.sectionText}>
                <Box pt={3} pb={3}>
                  <Typography
                    variant="body1"
                    className={classes.sectionContent}
                  >
                    We get our fingers dirty in the data mapping trenches by
                    migrating
                    <br />
                    your assets to the DAM system.
                    <br /> We design the metadata structure and we build the
                    org-wide
                    <br /> taxonomy from the ground up.
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button className={classes.button}>Read More</Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={1} sm={3} md={2} />
          </Grid>
        </Box>
      )}

      {isMobile && (
        <>
          <Typography
            variant="h4"
            className={classes.sectionTitle}
            style={{ textAlign: "center", paddingTop: 40 }}
          >
            DAM Journey
          </Typography>
          <Box
            pt={2}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={mediaPath + "damJourney.png"} width={170} height={170} />
          </Box>
          <Box pr={0} pb={2} className={classes.sectionText}>
            <Box pt={1} pb={2}>
              <Typography variant="body1" className={classes.sectionContent}>
                We walk with you through your Asset Lifecycle Management
                journey. The destinations during this journey are:
                <br /> Create --> Manage --> Distribute --> Repurpose -->
                Archive
                <br />
                <br />
                Create : Design, capture, and ingest <br />
                Manage : Review, automate, and approve
                <br /> Distribute: dynamic delivery, and share
                <br /> Repurpose : Search, find, collect, and reuse
                <br /> Archive : expire, and preserve
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button className={classes.button}>Read More</Button>
            </Box>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box pt={8} pb={4}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2} />
            <Grid
              item
              xs={7}
              sm={6}
              md={5}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h4" className={classes.sectionTitle}>
                DAM Journey
              </Typography>
              <Box pr={2} className={classes.sectionText}>
                <Box pt={3} pb={5}>
                  <Typography
                    variant="body1"
                    className={classes.sectionContent}
                    style={{ marginRight: isTablet && "30px" }}
                  >
                    We walk with you through your Asset Lifecycle Management
                    journey.
                    <br />
                    The destinations during this journey are:
                    <br /> Create --> Manage --> Distribute --> Repurpose -->
                    Archive
                    <br />
                    <br />
                    Create : Design, capture, and ingest <br />
                    Manage : Review, automate, and approve
                    <br /> Distribute: dynamic delivery, and share
                    <br /> Repurpose : Search, find, collect, and reuse
                    <br /> Archive : expire, and preserve
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button className={classes.button}>Read More</Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              sm={4}
              md={3}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box style={{ height: 83 }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={mediaPath + "damJourney.png"}
                  width={isTablet ? 224 : 314}
                  height={isTablet ? 224 : 314}
                />
              </Box>
            </Grid>
            <Grid item xs={1} sm={3} md={2} />
          </Grid>
        </Box>
      )}

      <Box className={classes.quoteBox}>
        <Box
          sx={{
            backgroundImage: `url(${quote})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "16px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography variant="h1" className={classes.quoteQuoteT}>
              <i>“</i>
            </Typography>

            <Typography
              variant="h1"
              className={classes.quoteText}
              style={{ fontSize: 26 }}
            >
              <i>
                I'm on this journey with you. I'm not here to do this journey at
                you. I'm in this with you.
              </i>
              <i>
                <span className={classes.quoteTextPer}>-- Claire Clark</span>
              </i>
            </Typography>

            <Typography variant="h1" className={classes.quoteQuoteB}>
              <i>”</i>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pt={5} className={classes.section}>
        <Box className={classes.sectionText} style={{ marginBottom: 10 }}>
          <Typography
            variant="h4"
            className={classes.sectionTitle}
            style={{ display: "flex", justifyContent: "center" }}
          >
            Success Stories
          </Typography>
          <Box pt={isMobile ? 2 : 5} pb={isMobile ? 2 : 5}>
            <Grid container justifyContent="center">
              {cards.map((card, index) => (
                <Grid item key={index} xs={4} sm={4} md={4}>
                  <Card
                    sx={{ borderRadius: "16px" }}
                    className={classes.card}
                    onClick={() =>
                      setOpenStoryDlg({
                        title: card.title,
                        msg: card.longText,
                      })
                    }
                  >
                    <CardContent
                      sx={{
                        padding: isMobile ? "10px" : "24px",
                        backgroundColor: "#F7F7F7",
                      }}
                    >
                      <Typography variant="body1" className={classes.cardText}>
                        {card.shortText}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h6"
                          className={classes.cardCompany}
                          color="primary"
                        >
                          {card.companyName}
                        </Typography>
                        <IconButton
                          className={classes.addCircle}
                          onClick={() =>
                            setOpenStoryDlg({
                              title: card.title,
                              msg: card.longText,
                            })
                          }
                        >
                          <AddIcon
                            style={{
                              fontSize: isMobile
                                ? "16px"
                                : isTablet
                                ? "20px"
                                : "28px",
                            }}
                          />
                        </IconButton>
                      </Box>
                      <Box pb={isMobile ? 0 : 3}>
                        <Divider />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        pt={6}
        pb={3}
        className={classes.damQuesSection}
        style={{ justifyContent: "center" }}
      >
        <Box
          className={classes.damQuesBox}
          sx={{
            backgroundImage: `url(${damQues})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {isMobile ? (
            <Typography
              variant="h4"
              className={classes.sectionTitle}
              style={{ fontSize: 22, paddingTop: 20 }}
            >
              DAM Questionnaire
            </Typography>
          ) : (
            <Typography variant="h4" className={classes.sectionTitle}>
              DAM Questionnaire
            </Typography>
          )}
          <Box
            pl={isMobile ? 2 : 5}
            pr={isMobile ? 2 : 5}
            pt={isMobile ? 2 : 7}
            pb={isMobile ? 2 : 5}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {isTablet ? (
              <>
                <Typography variant="body1" className={classes.sectionContent}>
                  Startup Framework gives you complete freedom over your
                  creative process —
                </Typography>
                <Typography variant="body1" className={classes.sectionContent}>
                  you don’t have to think about any technical aspects.
                </Typography>
                <Typography variant="body1" className={classes.sectionContent}>
                  There are no limits and absolutely no coding.
                </Typography>
              </>
            ) : isMobile ? (
              <>
                <Typography variant="body1" style={{ fontSize: 8 }}>
                  Startup Framework gives you complete freedom over your
                  creative process —
                </Typography>
                <Typography variant="body1" style={{ fontSize: 8 }}>
                  you don’t have to think about any technical aspects.
                </Typography>

                <Typography variant="body1" style={{ fontSize: 8 }}>
                  There are no limits and absolutely no coding.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body1" className={classes.sectionContent}>
                  Startup Framework gives you complete freedom over your
                  creative process — you don’t have to think about any technical
                  aspects.
                </Typography>
                <Typography variant="body1" className={classes.sectionContent}>
                  There are no limits and absolutely no coding.
                </Typography>
              </>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <Button
              className={classes.button}
              style={{
                width: isMobile && "70px",
                height: isMobile && "20px",
              }}
            >
              More
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        pt={8}
        pb={isMobile ? 5 : 10}
        className={classes.historySection}
        style={{ justifyContent: "center" }}
      >
        <Typography variant="h4" className={classes.sectionTitle} gutterBottom>
          Our History
        </Typography>
        <Typography
          variant="body1"
          className={classes.sectionContent}
          style={{
            fontSize: isMobile && 10,
            textAlign: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
          gutterBottom
        >
          <b>Get started today!</b> Contact us to learn more about how our{" "}
          <i>DAM services</i> can transform the way you manage your digital
          assets.
        </Typography>
        <Box className={classes.historyBox}>
          <Typography variant="h1" className={classes.historyTitle}>
            {selectedYear}
          </Typography>
          <Typography variant="h1" className={classes.historyContent}>
            {historyData[selectedYear]}
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {Object.keys(historyData).map((year) => (
            <Grid item key={year}>
              <Typography
                variant="h1"
                className={classes.historyYear}
                //variant={selectedYear === year ? "contained" : "outlined"}
                style={{ color: selectedYear === year ? "#021CA5" : "#9A9A9A" }}
                onClick={() => setSelectedYear(year)}
              >
                {year}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: isMobile ? 10 : 20,
          }}
        >
          <IconButton
            className={classes.arrowIcon}
            style={{ marginRight: "20px" }}
            onClick={() =>
              setSelectedYear(
                (prev) =>
                  Object.keys(historyData)[
                    Math.max(Object.keys(historyData).indexOf(prev) - 1, 0)
                  ]
              )
            }
          >
            <ArrowBackIcon
              style={{
                fontSize: isMobile ? "18px" : isTablet ? "30px" : "35px",
              }}
            />
          </IconButton>
          <IconButton
            className={classes.arrowIcon}
            onClick={() =>
              setSelectedYear(
                (prev) =>
                  Object.keys(historyData)[
                    Math.min(
                      Object.keys(historyData).indexOf(prev) + 1,
                      Object.keys(historyData).length - 1
                    )
                  ]
              )
            }
          >
            <ArrowForwardIcon
              style={{
                fontSize: isMobile ? "18px" : isTablet ? "30px" : "35px",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      {openStoryDlg.msg && (
        <AlertDialog
          title={openStoryDlg.title}
          contentMsg={openStoryDlg.msg}
          closeDialog={() => setOpenStoryDlg([])}
        />
      )}
    </Container>
  );
};

export default HomePage;
