import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useMutation, ClientContext, GraphQLClient } from "graphql-hooks";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import typography from "./theme/typography";
import palette from "./theme/palette";
//// Static Pages

import StaticFooter from "./staticPages/Footer";
import StaticHeader from "./staticPages/Header";
import HomePage from "./staticPages/HomePage.js";
////
import Header from "./components/Header";
import Footer from "./components/Footer";
import AssetsSideMenu from "./userPages/AssetsSideMenu";
import AssetsListFull from "./userPages/brandfolderAssets/AssetsListFull";
import AssetsListFullSP from "./userPages/brandfolderAssets/AssetsListFullSP";
import Home from "./userPages/Home";
import SignIn from "./userPages/SignIn";
import Logout from "./userPages/Logout";
import ToBeDev from "./userPages/ToBeDev";

const theme = createMuiTheme({
  shadows: ["none"],
  typography: {
    fontFamily: [
      'SF Pro Display',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 950, //600
      md: 1100,//900 1400
      lg: 1400,//1600,
      xl: 1536,//1900,
      customBreakpoint: 735,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    //  flex: "1 1 auto",
    overflow: "auto",
    minHeight: "100vh",
    minWidth: "1790px", //1500 IE-111 SP
  },
  staticRoot: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    //  flex: "1 1 auto",
    overflow: "auto",
    minHeight: "100vh",
  },

  sideMenuWrapper: {
    display: "flex",
    flexDirection: "row",
    minWidth: "1616px",
  },
  wrapper: {
    overflow: "auto",
    flex: "1 1 auto",
    paddingTop: "6rem",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function App() {
  const classes = useStyles();
  const getToken = () => localStorage.getItem("token");
  const token = getToken();
  const [staticPage, setStaticPage] = useState(false);
  const [sideMenu, setSideMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [previewPage, setPreviewPage] = useState(false);
  const [publicPage, setPublicPage] = useState(false);

  const client = new GraphQLClient({
    // url: "http://localhost:8000/cul/graphql",
    // // useGETForQueries: false,
    url: "/cul/graphql",
    headers: { Authorization: token ? `JWT ${token}` : "" },
    fetchOptions: () => {
      return {
        headers: { Authorization: token ? `JWT ${token}` : "" },
      };
    },
  });
  const StaticPageLayout = () => {
      setStaticPage(true);
      const location = useLocation();
      console.log(location);
      // if (
      //   location.pathname.indexOf("home-page") !== -1 ||
      //   location.pathname.indexOf("index.html") !== -1 ||
      //   location.pathname === "/cul/" ||
      //   location.pathname === "/"
      // )
      return <HomePage />;
    };
  const HandleHome = ({ homeTarget }) => {
    setSideMenu(null);
    return <Home />;
  };
  const HandleLogout = () => {
    setSideMenu(null);
    return <Logout />;
  };
  const AssetsPageLayout = () => {
    const location = useLocation();
    setSideMenu("assetsMenu");
    setSelectedMenu("Assets List");
    if (location.pathname.indexOf("/cul/assets-list-full-sp") != -1)
      return <AssetsListFullSP />;
    else if (location.pathname.indexOf("/cul/assets-list-full") != -1)
      return <AssetsListFull />;
  };
  ////////////////////////
  return (
    <ClientContext.Provider value={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className={!staticPage ? classes.root : classes.staticRoot }>
          {!staticPage && <Header />}
          {staticPage && <StaticHeader />}
            <div
              className={
                sideMenu
                  ? classes.sideMenuWrapper
                  : !publicPage && !staticPage
                  ? classes.wrapper
                  : null
              }
            >
              {sideMenu && sideMenu === "assetsMenu" && (
                <AssetsSideMenu selectedMenu="Assets List" />
              )}

              <Routes>
                <Route path="/cul/signin" element={<SignIn />} />
                <Route path="/cul/signout" element={<HandleLogout />} />
                //<Route path="*" element={<HandleHome />} />
                <Route path="/cul/assets-list" element={<AssetsPageLayout />} />
                <Route path="/cul/assets-list-full" element={<AssetsPageLayout />} />
                <Route path="/cul/assets-list-full-SP" element={<AssetsPageLayout />} />
                <Route path="/cul/TBD" element={<ToBeDev />} />
                <Route path="/fWH5Z38g" element={<StaticPageLayout />} />
              </Routes>
            </div>
            {!staticPage && <Footer />}
            {staticPage && <StaticFooter />}
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </ClientContext.Provider>
  );
}
ReactDOM.render(<App />, document.getElementById("root"));
