const RefreshToken_mutate = `
    mutation RefreshToken($refreshToken: String!) {
	  refreshToken(refreshToken: $refreshToken) {
		token
		payload
		refreshToken
		refreshExpiresIn
	  }
	}
`;

const ForgetPassword_mutate = `
    mutation forgetPassword(
      $email: String!) {
      forgetPassword(
        email: $email) {
        userId
		email
      }
    }
`;

const ResetPassword_mutate = `
    mutation resetPassword(
      $password: String!,
	  $forgetCode: String!) {
      resetPassword(
        password: $password,
		forgetCode: $forgetCode) {
        userId
      }
    }
`;


const ActivatePublicUser_mutate = `
    mutation activatePublicUser(
      $password: String!,
    $activationCode: String!) {
      activatePublicUser(
        password: $password,
    activationCode: $activationCode) {
        userId
      }
    }
`;

const ActivateUser_mutate = `
    mutation activateUser($activationCode: String!) {
      activateUser(activationCode: $activationCode) {
        userId
       activationCode
      }
    }
`;

module.exports = {
  REFRESH_TOKEN_MUTATION: RefreshToken_mutate,
  FORGET_PASSWORD_MUTATION: ForgetPassword_mutate,
  RESET_PASSWORD_MUTATION: ResetPassword_mutate,
  ACTIVATE_USER_MUTATION : ActivateUser_mutate,
  ACTIVATE_PUBLICUSER_MUTATION: ActivatePublicUser_mutate
};
