import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useManualQuery, ClientContext } from "graphql-hooks";

import {
  Container,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  Radio,
  Checkbox,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";

import styled from "styled-components";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AlertDialog from "../../components/AlertDialog.js";
import ActivityLogDialog from "./ActivityLogDialog.js";
import AssetDetailDialog from "./AssetDetailDialog.js";

import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import {
  ASSET_LIST_QUERY,
  UPLOAD_ASSET_MUTATION,
  Set_Asset_Key_Approved_MUTATION,
} from "../../graphQL/asset_qry";

var findAnd = require("find-and");

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "0px, -50px, 20px, -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "50px",
    textAlign: "center",
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    width: "1614px",
    paddingTop: "9rem", //"7.5rem",
    //  minWidth: "500px",
  },
  container: {
    padding: theme.spacing(2),
    marginTop: "1rem",
    marginBottom: "5rem",
    overflow: "hidden",
    clear: "both",
    borderRadius: "8px",
    minHeight: "902px",
    backgroundColor: "#ffffff",
  },
  autoComStyle: {
    "& .MuiFormControl-root": {
      width: 200,
      //  marginTop: 15,
      marginBottom: 2,
      "& .MuiInputLabel-outlined": {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      fontSize: 14,
    },
  },
  dataGridStyle: {
    "&.MuiDataGrid-scrollbar MuiDataGrid-scrollbar--horizontal css-1rtad1": {
      position: "relative",
      display: "block",
    },
    "&.MuiDataGrid-root": {
      //  minWidth: 980,
      //  width:"100%"
      //  overflowX: 'scroll'
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "#fff !important",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#fff !important",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
      fontSize: 12,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
}));

const assetsOption = [
  { label: "Set-1" },
  { label: "Set-2" },
  { label: "Set-3 Robinia" },
  { label: "Set-3 Sandy" },
  { label: "Set-3 Sami" },
  { label: "Set-4" },
  { label: "Test-1" },
];
//const sectionOption = [
// { label: "All" },
// { label: "Corporate Assets" },
// { label: "Dealer Assets" },
// { label: "Digital Assets" },
//];

//const subtypeOptionInitValue = [
//{ section: "All", label: "All" },
// { section: "Corporate Assets", label: "Battle Card" },
//  { section: "Corporate Assets", label: "Email" },
//  { section: "Dealer Assets", label: "Uniform" },
// { section: "Dealer Assets", label: "Vehicle Fleet" },
// { section: "Digital Assets", label: "Layout" },
// { section: "Digital Assets", label: "Organic Social" },
//];

function AssetsListFull() {
  const classes = useStyles();
  const client = useContext(ClientContext);
  const [assetSetValue, setAssetSetValue] = useState("Set-1");
  const [sectionValue, setSectionValue] = useState("All");
  const [sectionOption, setSectionOption] = useState([{ label: "All" }]);
  const [subtypeOptionInitValue, setSubtypeOptionInitValue] = useState([
    { section: "All", label: "All" },
  ]);
  const [subtypeValue, setSubtypeValue] = useState("All");
  const [subtypeOption, setSubtypeOption] = useState(subtypeOptionInitValue);
  const [confirmAlertMsg, setConfirmAlertMsg] = useState([]);
  const [openAssetDetailDlg, setOpenAssetDetailDlg] = useState(false);
  const [openActivityLogDlg, setOpenActivityLogDlg] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [rowsList, setRowsList] = useState([]);
  const [filterdRowsList, setFilteredRowsList] = useState([]);
  const [pathname] = useState(window.location.pathname);
  
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else window.location.href = `/cul/signin/?next=${pathname}`;
  }, []);
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        window.location.href = `/cul/signin/?next=${pathname}`;
      if (refreshError.graphQLErrors[0].message === "Signature has expired" || refreshError.graphQLErrors[0].message === "Invalid refresh token")
        window.location.href = `/cul/signin/?next=${pathname}`;
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  const [fetchAssetsList] = useManualQuery(ASSET_LIST_QUERY, {
    fetchPolicy: "network-only",
  });

  const [uploadAsset] = useMutation(UPLOAD_ASSET_MUTATION);
  const [setAssetKeyApproved] = useMutation(Set_Asset_Key_Approved_MUTATION);
  const apiKey = localStorage.getItem("api_key")
    ? localStorage.getItem("api_key")
    : "eyJhbGciOiJIUzI1NiJ9.eyJvcmdhbml6YXRpb25fa2V5IjoiY3JxamYzOXJyNms5cHA1Y3duY2M5cGYiLCJpYXQiOjE3MzIzNDMwOTMsInVzZXJfa2V5IjoiZzZyMzR4cDVtaHd2YjZraGJzNXRzOSIsInN1cGVydXNlciI6ZmFsc2V9.Ky65qD4CCu3c80z2WZjKG5B7jNOGpsDzLQoIfDoVSsM";

  const getAssetsList = async () => {
    let optionList = [{ label: "All" }];
    let subtypeOptionList = [{ section: "All", label: "All" }];
    const {
      loading: assetsListLoading,
      error: assetsListError,
      data: assetsListData,
    } = await fetchAssetsList({
      variables: {
        setName: assetSetValue,
      },
    });

    if (!assetsListLoading && assetsListData && assetsListData.allAssets) {
      setAssetsList(assetsListData.allAssets);
      //update section options according to set name

      for (var i = 0; i < assetsListData.allAssets.length; i++) {
        let search = optionList.find(
          (item) =>
            item.label.toLowerCase() ===
            assetsListData.allAssets[i].section.toLowerCase()
        );

        if (!search) {
          optionList.push({ label: assetsListData.allAssets[i].section });
        }

        //add subtype if not exits
        let searchSubtype = subtypeOptionList.find(
          (item) =>
            item.section.toLowerCase() ===
              assetsListData.allAssets[i].section.toLowerCase() &&
            item.label.toLowerCase() ===
              assetsListData.allAssets[i].assetSubtype.toLowerCase()
        );
        if (!searchSubtype) {
          subtypeOptionList.push({
            section: assetsListData.allAssets[i].section,
            label: assetsListData.allAssets[i].assetSubtype,
          });
        }
      }

      setSectionOption(optionList);
      setSubtypeOptionInitValue(subtypeOptionList);
    }
  };

  const updateRowsList = async () => {
    console.log("assetsList", assetsList);
    setRowsList(
      assetsList.map((ass, index) => ({
        index,
        id: ass.assetId,
        assetFileName: ass.assetFilename,
        section: ass.section,
        logActivity:
          ass.logActivity !== null
            ? ass.logActivity.split("|")
            : ["No activity log"],
        assetSubtype: ass.assetSubtype,
        attachments: ass.attachment ? ass.attachment.split("|") : [],
        uploadStatus: ass.statusUpload,
        dateUploaded: ass.dateUploaded,
        assetKey: ass.bfAssetKey,
        assetName: ass.assetName,
        assetDescription: ass.assetDesc !== null ? ass.assetDesc : "Not Defined",
        assetStatus: ass.statusAsset,
        labels: ass.assetLabels !== null ? ass.assetLabels.split("|") : ["No Label"], //[ass.assetLabels.replaceAll("|",", ")],
        customFields: [
          {
            label: "Product Name",
            value: ass.cfProductName !== null ? ass.cfProductName : "Not Defined",
          },
          {
            label: "Product ID",
            value: ass.cfProductId !== null ? ass.cfProductId : "Not Defined",
          },
          {
            label: "Part No",
            value: ass.cfPartNo !== null ? ass.cfPartNo : "Not Defined",
          },
          {
            label: "Channels",
            value: ass.cfChannels !== null ? ass.cfChannels : "Not Defined",
          },
          {
            label: "Promo Name",
            value: ass.cfPromoName !== null ? ass.cfPromoName : "Not Defined",
          },
          {
            label: "Asset Vendor Ref",
            value: ass.cfAssetVendorRef !== null ? ass.cfAssetVendorRef : "Not Defined",
          },
        ],
      }))
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (() => getAssetsList())();
  }, [assetSetValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubtypeOption(subtypeOptionInitValue);
  }, [subtypeOptionInitValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    (() => updateRowsList())();
  }, [assetsList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFilteredRowsList(rowsList);
  }, [rowsList]);

  // console.log("rowList", rowsList);

  const formatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);

    const newFormat =
      dayFormat.find(a => a.type == "month").value +
      "/" +
      dayFormat.find(a => a.type == "day").value +
      "/" +
      dayFormat.find(a => a.type == "year").value;
    return newFormat;
  };
  const columns = [
    {
      field: "more",
      headerName: "",
      headerClassName: "super-app-theme--header",
      maxWidth: 3,
      //  flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (rows) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledTooltip title="Asset Detail">
              <MoreHorizIcon
                color="action"
                sx={{
                  cursor: "pointer",
                  "&.MuiSvgIcon-root:hover": {
                    backgroundColor: "lightGray",
                    borderRadius: 1,
                  },
                }}
                onClick={() => {
                  const foundRow = filterdRowsList.filter(
                    (row) => row.id === rows.row.id
                  );
                  if (foundRow.length > 0) setOpenAssetDetailDlg(foundRow[0]);
                }}
              />
            </StyledTooltip>
          </Box>
        );
      },
    },
    {
      field: "assetFileName",
      headerName: "Asset File Name",
      headerClassName: "super-app-theme--header",
      minWidth: 350,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "section",
      headerName: "Section",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      //  flex: 1,
      sortable: false,
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
    },

    {
      field: "assetSubtype",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Sub-type",
      minWidth: 140,
      //  flex: 1,
      sortable: false,
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "assetName",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Name",
      minWidth: 100,
      flex: 1,
      sortable: false,
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
    },

    {
      field: "uploadStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Upload Status",
      minWidth: 120,
      //  flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "dateUploaded",
      headerClassName: "super-app-theme--header",
      headerName: "Date Uploaded",
      minWidth: 110,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "assetKey",
      headerClassName: "super-app-theme--header",
      headerName: "Asset key",
      minWidth: 170,
      //  flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },

    {
      field: "assetStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Asset Status",
      minWidth: 120,
      //    flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 20,
      //    flex: 2,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: rows => {
        return (
          <Box
            //pt={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <StyledTooltip
              title={
                rows.row.uploadStatus === "Successful"
                  ? "Upload Successful"
                  : "Upload Asset"
              }
            >
              <IconButton
                style={{
                  cursor: rows.row.uploadStatus === "Successful" ? "default" : "pointer",
                }}
              >
                <UploadFileIcon
                  color={rows.row.uploadStatus === "Successful" ? "disabled" : "action"}
                  onClick={() =>
                    //handleUploadAsset(rows.row.id)
                    rows.row.uploadStatus !== "Successful" &&
                    setConfirmAlertMsg({
                      title: "Upload Asset",
                      type: "Confirmation",
                      msg:
                        "Are you ready to upload the “" +
                        rows.row.assetFileName +
                        "” asset?",
                      confirmButton: "Upload",
                      id: rows.row.id,
                    })
                  }
                />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title="Upload log activity">
              <IconButton>
                <PendingActionsIcon
                  color="action"
                  onClick={() => {
                    setOpenActivityLogDlg(rows.row.logActivity);
                  }}
                />
              </IconButton>
            </StyledTooltip>
          </Box>
        );
      },
    },
  ];
  function generateRandomString(length) {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  function DataGridTitle() {
    return (
      <>
        <Grid
          container
          xs={12}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h1" style={{ fontSize: 20 }}>
              Assets List
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </>
    );
  }

  const handleChangeAssetsOptionValue = value => {
    setSectionValue("All");
    setSubtypeValue("All");
    setAssetSetValue(value);
  };
  const handleChangeSectionOptionValue = val => {
    const value = val ? val : "All";
    setSectionValue(value);
    setSubtypeValue("All");
    if (value == "All") {
      // set init values
      setFilteredRowsList(rowsList);
      setSubtypeOption(subtypeOptionInitValue);
    } else {
      // set filtered values
      const draftList = rowsList.filter(r => r.section === value);
      const subtypeOptionList = subtypeOptionInitValue.filter(
        sub => sub.section === value || sub.section === "All"
      );
      setSubtypeOption(subtypeOptionList);
      setFilteredRowsList(draftList);
    }
  };
  const handleChangesubtypeOptionValue = val => {
    const value = val ? val : "All";
    setSubtypeValue(value);
    let sectionFilteredList = rowsList;
    if (sectionValue && sectionValue !== "All")
      sectionFilteredList = rowsList.filter(r => r.section === sectionValue);
    if (value === "All") {
      setFilteredRowsList(sectionFilteredList);
    } else {
      const draftList = sectionFilteredList.filter(r => r.assetSubtype === value);
      setFilteredRowsList(draftList);
    }
  };

  const handleUploadAsset = async id => {
    let currRowData = findAnd.returnFound(filterdRowsList, { id: id });
    console.log(currRowData);
    const customFieldsObject = currRowData.customFields.reduce((acc, item) => {
      if (item.value !== "Not Defined") {
        acc[item.label] = item.value;
      }
      return acc;
    }, {});
    customFieldsObject["Asset Sub-type"] = currRowData.assetSubtype;

    let {
      loading: loadUploadAsset,
      error: errorUploadAsset,
      data: dataUploadAsset,
    } = await uploadAsset({
      variables: {
        apiKey: apiKey,
        assetName: currRowData.assetName,
        assetDescription: currRowData.assetDescription,
        sectionName: currRowData.section,
        assetFileName: currRowData.assetFileName,
        // assetSetSrvPath: "https://damjourney.com/culligan/dam/rs/set-3",
        assetSetSrvPath: "https://damjourney.com/culligan/dam/rs/".concat(
          assetSetValue.toLowerCase()
        ),
        attachments: currRowData.attachments,
        labels: currRowData.labels,
        customFieldVals: JSON.stringify(customFieldsObject),
      },
    });

    if (!loadUploadAsset && dataUploadAsset) {
      let {
        loading: loadSetAssetKeyApproved,
        error: errorSetAssetKeyApproved,
        data: dataSetAssetKeyApproved,
      } = await setAssetKeyApproved({
        variables: {
          assetId: parseInt(currRowData.id),
          assetKey: dataUploadAsset.processData.assetKeyResult,
          assetApproved: dataUploadAsset.processData.assetStatusResult,
        },
      });

      if (!loadSetAssetKeyApproved && dataSetAssetKeyApproved) {
        console.log(dataSetAssetKeyApproved.setAssetKeyApproved.asset);

        let updatedRowsList = findAnd.changeProps(
          filterdRowsList,
          { id: id },
          {
            uploadStatus: dataSetAssetKeyApproved.setAssetKeyApproved.asset.statusUpload,
            dateUploaded: dayFormat(
              dataSetAssetKeyApproved.setAssetKeyApproved.asset.dateUploaded
            ),
            assetKey: dataSetAssetKeyApproved.setAssetKeyApproved.asset.bfAssetKey,
            assetStatus: dataSetAssetKeyApproved.setAssetKeyApproved.asset.assetApproved
              ? "Uploading"
              : "Pending",
          }
        );
        setFilteredRowsList(updatedRowsList);
      }
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container xs={12} spacing={3}>
          <Grid item>
            <Autocomplete
              options={assetsOption.map(({ label }) => label)}
              //disableClearable
              fullWidth
              value={assetSetValue}
              onChange={(event, value) => {
                handleChangeAssetsOptionValue(value);
              }}
              className={classes.autoComStyle}
              getOptionLabel={option => option}
              renderInput={params => (
                <TextField
                  //  required={true}
                  fullWidth
                  label="Select Assets Set"
                  margin="normal"
                  name="selectAssets"
                  id="selectAssets"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={sectionOption.map(({ label }) => label)}
              //  disableClearable
              fullWidth
              value={sectionValue}
              onChange={(event, value) => {
                handleChangeSectionOptionValue(value);
              }}
              className={classes.autoComStyle}
              getOptionLabel={option => option}
              renderInput={params => (
                <TextField
                  //    required
                  fullWidth
                  label="Select Section"
                  margin="normal"
                  name="selectAssets"
                  id="selectAssets"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={subtypeOption.map(({ label }) => label)}
              disabled={sectionValue === "All"}
              fullWidth
              value={subtypeValue}
              onChange={(event, value) => {
                handleChangesubtypeOptionValue(value);
              }}
              className={classes.autoComStyle}
              sx={{
                "& .MuiFormControl-root": {
                  width: 280,
                },
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  //  required
                  fullWidth
                  label="Select Asset Sub-type"
                  margin="normal"
                  name="selectAssets"
                  id="selectAssets"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box pt={6}>
          <DataGrid
            className={classes.dataGridStyle}
            columns={columns}
            rows={filterdRowsList}
            showCellRightBorder={true}
            //  hideFooter={true}
            autoWidth={true}
            slots={{
              toolbar: DataGridTitle,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowId={row => row.id}
            autoHeight={true}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
        {confirmAlertMsg.msg && (
          <AlertDialog
            title={confirmAlertMsg.title}
            type={confirmAlertMsg.type}
            contentMsg={confirmAlertMsg.msg}
            closeDialog={() => setConfirmAlertMsg([])}
            confirmAction={() => {
              setConfirmAlertMsg([]);
              handleUploadAsset(confirmAlertMsg.id);
            }}
            confirmButton={confirmAlertMsg.confirmButton}
          />
        )}
        {openAssetDetailDlg && (
          <AssetDetailDialog
            assetDetail={openAssetDetailDlg}
            assetSetValue={assetSetValue}
            closeDialog={() => setOpenAssetDetailDlg(false)}
          />
        )}
        {openActivityLogDlg && (
          <ActivityLogDialog
            activityLog={openActivityLogDlg}
            closeDialog={() => setOpenActivityLogDlg(false)}
          />
        )}
      </div>
    </div>
  );
}

export default AssetsListFull;
