import React, { useState, useEffect, useContext } from "react";
import { ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function    : Logout
## Description : logout user
## Author      : RR
## Date        : 2022/03/12
##########################################################################*/
export default function Logout() {
  const client = useContext(ClientContext);
  const navigate = useNavigate();
  useEffect(
    () => {
	    localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("username");
        client.removeHeader("Authorization");
      navigate(`/cul/signIn`);
    },
    [localStorage]
  );

  /////////////////////////////////////////////////////////////////////
  return (
    <>
    <title> DAM | Log Out</title>

    <h2>
      You are logged out. You will be redirected to login page.
    </h2>
    </>
  );
}
