import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import AttachmentsDialog from "./assetDetailTabs/AttachmentsDialog.js";
import CustomFieldsTab from "./assetDetailTabs/CustomFieldsTab.js";
import AssetInfoTab from "./assetDetailTabs/AssetInfoTab.js";
import LabelsTab from "./assetDetailTabs/LabelsTab.js";
import { asset_set_srv_path } from "../../constants/Data";

const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    padding-top: 10px;
    min-height: 20px;
    height: 40px;
    width: 200px;
    background-color: white;
  }
`;
const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#0D47A1",
    },
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AssetDetailDialog({
  title,
  assetDetail,
  assetSetValue,
  closeDialog,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(0);
  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const assetInfo = [
    {
      label: "File Name",
      value: assetDetail.assetFileName,
      url:
        asset_set_srv_path.concat(assetSetValue.toLowerCase()) +
        "/" +
        assetDetail.assetFileName,
    },
    { label: "Asset Name", value: assetDetail.assetName },
    { label: "Description", value: assetDetail.assetDescription },
    { label: "Attachment File Name", value: assetDetail.attachments },
  ];

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "900px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={11}
              style={{
                textAlign: "center",
                paddingLeft: 15,
              }}
            >
              Asset Detail
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0D47A1",
                  },
                }}
                style={{ paddingLeft: "5px", height: "48px" }}
                variant="standard" //"fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Asset Info"
                  style={{
                    width: 150,
                    fontSize: 16,
                    fontWeight: 600,
                    color: value === 0 ? "#0D47A1" : "#4D4D4D",
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    width: 170,
                    fontSize: 16,
                    fontWeight: 600,
                    minHeight: 0,
                    color: value === 1 ? "#0D47A1" : "#4D4D4D",
                  }}
                  label="Custom Fields"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    width: 170,
                    fontSize: 16,
                    fontWeight: 600,
                    color: value === 2 ? "#0D47A1" : "#4D4D4D",
                  }}
                  label="Labels"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 40,
            }}
          >
            <Grid item xs={11} sm={11} md={11}>
              <TabPanel value={value} index={0}>
                <AssetInfoTab
                  assetInfo={assetInfo}
                  assetSetValue={assetSetValue}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CustomFieldsTab customFieldsList={assetDetail.customFields} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <LabelsTab labelsList={assetDetail.labels} />
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              style={{ marginRight: 5 }}
              onClick={handleClose}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
