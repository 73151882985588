import React, { useState } from "react";
import { Button, Typography, IconButton, makeStyles } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#0D47A1",
    height: "34px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
  closeIcon: {
    //marginTop: "30px",
    backgroundColor: "#021CA5",
    color: "#fff",
    border: "1px solid #021CA5",
    borderRadius: "100%",
    width: 35,
    height: 35,
    "&:hover": {
      backgroundColor: "#021CA5",
      color: "#fff",
    },
  },
}));

export default function AlertDialog({
  title,
  contentMsg,
  type,
  confirmAction,
  confirmButton,
  closeDialog,
  headerMsg,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const isMobile = useMediaQuery(`(max-width:800px)`);
  const isSMMobile = useMediaQuery(`(max-width:400px)`);

  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog();
  };
  const handleConfirm = () => {
    setOpen(false);
    confirmAction && confirmAction();
  };

  return (
    <div style={{ width: "100%" }}>
      {!type && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onKeyDown={(e) => {
            e.key === "Enter" && handleClose();
          }}
          sx={{
            "& .MuiDialog-paper": {
              width: "800px", //isTablet ? "400px" : "800px",
              minHeight: "400px", //isTablet ? "200px" : "400px",
              borderRadius: "16px",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px",
              paddingBottom: 0,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeIcon}
            >
              <CloseIcon
                style={{
                  fontSize: "24px",
                }}
              />
            </IconButton>
          </Box>
          <DialogTitle
            id="alert-dialog-title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: isMobile ? 60 : 80,
              paddingRight: isMobile ? 60 : 80,
              paddingBottom: isSMMobile ? 40 : 60,
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            style={{
              paddingLeft: isMobile ? 60 : 80,
              paddingRight: isMobile ? 60 : 80,
              paddingBottom: isMobile ? 60 : 80,
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {contentMsg}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
