import React, { useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
  Button,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#0D47A1",
    height: "34px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
}));

export default function CustomFieldsTab({ customFieldsList, closeDialog }) {
  const classes = useStyles();

  return (
    <div style={{ width: "100%", minWidth: 500, minHeight:220 }}>
      {customFieldsList.map((item, index) => (
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <Grid
            item
            xs={6}
            style={{
              paddingRight: 5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="h2">{item.label}:</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {typeof item.value === "string" && (
              <Typography variant="h3">{item.value}</Typography>
            )}
            {typeof item.value === "object" && (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="h3">{item.value[0]}</Typography>
                  {item.value[1] && (
                    <>
                      <Typography variant="h3" style={{ paddingRight: 5 }}>
                        ,
                      </Typography>
                      <Typography variant="h3">{item.value[1]}</Typography>
                    </>
                  )}
                </Box>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {item.value[2] && (
                    <>
                      <Typography variant="h3">{item.value[2]}</Typography>
                      {item.value[3] && (
                        <>
                          <Typography variant="h3" style={{ paddingRight: 5 }}>
                            ,
                          </Typography>
                          <Typography variant="h3">{item.value[3]}</Typography>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
