import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles, AppBar, Button, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: "134px",
    padding: "30px",
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "white",
    color: "black",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  menuButton: {
    display: "block",
    color: "black",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoMobile: {
    flexGrow: 1,
    display: "block",
    color: "black",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    flexGrow: 1,
    textAlign: "left",
    height: "53px",
    display: "flex",
    alignItems: "center",
    color: "black",
  },
  navLinks: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  navButton: {
    paddingRight: "40px",
    fontSize: "22px",
    fontWeight: 600,
    textTransform: "none",
    color: "black",
    [theme.breakpoints.down("md")]: {
      paddingRight: "16px",
      fontSize: "16px",
    },
  },
  contactButton: {
    width: "146px",
    height: "54px",
    fontSize: "18px",
    textTransform: "none",
    backgroundColor: "#021CA5",
    color: "white",
    borderRadius: "100px",
    [theme.breakpoints.down("md")]: {
      width: "110px",
      height: "40px",
      fontSize: "16px",
    }
  },
  drawerContainer: {
    width: 250,
  },
}));

const Header = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          aria-label="menu"
          className={classes.menuButton}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Typography
        variant="body1"
          className={classes.logo}
          style={{fontSize:"30px"}}
        >
          Logo
        </Typography>
        <Box className={classes.navLinks}>
          <Button className={classes.navButton}>About</Button>
          <Button className={classes.navButton}>Differentiation</Button>
          <Button className={classes.navButton}>Services</Button>
          <Button className={classes.navButton}>Product Agnostic</Button>
          <Button className={classes.navButton}>DAM Classes</Button>
        </Box>
        <Button variant="contained" className={classes.contactButton}>
          Contact
        </Button>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            className={classes.drawerContainer}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {[
                "About",
                "Differentiation",
                "Services",
                "ProductAgnostic",
                "DAM Classes"
              ].map((text) => (
                <ListItem button key={text}>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
