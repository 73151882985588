import React, { useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
  Button,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

import { asset_set_srv_path } from "../../../constants/Data";

const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#0D47A1",
    height: "34px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
}));

export default function AssetInfoTab({ assetInfo, assetSetValue }) {
  const classes = useStyles();
  return (
    <div style={{ width: "100%", minWidth: 500, minHeight: 220 }}>
      {assetInfo.map((item, index) => (
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              paddingRight: 5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="h2">{item.label}:</Typography>
          </Grid>
          <Grid
            item
            xs={9}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {typeof item.value === "string" &&
              (item.url ? (
                <Typography variant="h3">
                  <a href={item.url} target="_blank">
                    {item.value}
                  </a>
                </Typography>
              ) : (
                <Typography variant="h3">{item.value}</Typography>
              ))}
            {typeof item.value === "object" && (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                {item.value.map((i, index) => (
                  <a
                    href={asset_set_srv_path.concat(
                      assetSetValue.toLowerCase() + /attachment/ + i
                    )}
                    target="_blank"
                  >
                    {i}
                  </a>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
