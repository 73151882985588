import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import CryptoJS from "crypto-js";

import {
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    maxWidth: 430,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const welcome_page_text = "Welcome to the API Portal. This portal serves as an entry into the DAM system. This is an integration service that assists users to upload onto the DAM system assets, and their associated attachment(s), and metadata in one step. The upload process is quick and reliable, and the metadata is accurate."

const LOGIN_USER_MUTATION = `
    mutation loginUser($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
        refreshToken
        payload
      }
    }
`;

const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [autoLoginFlag, setAutoLoginFlag] = useState(localStorage.getItem("autoLogin"));
  const [usernameFlag, setUsernameFlag] = useState(localStorage.getItem("username"));
  const [login] = useMutation(LOGIN_USER_MUTATION);
  const navigate = useNavigate();
  const client = useContext(ClientContext);
  const [nextUrl] = useSearchParams();
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  // useEffect(() => {
  //   const setResponsiveness = () => {
  //     return window.innerWidth < 768
  //       ? setState(() => ({ mobileView: true }))
  //       : window.innerWidth < 821 && window.innerWidth > 767
  //       ? setState(() => ({ mobileView: false, tabletView: true }))
  //       : setState(() => ({ tabletView: false }));
  //   };
  //   setResponsiveness();
  //   window.addEventListener("resize", () => setResponsiveness());
  //   return () => {
  //     window.removeEventListener("resize", () => setResponsiveness());
  //   };
  // }, []);

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMsg("");
    setErrorMsg("");
  }, 10000000000);

  const onSubmit = async formData => {
    console.log("DATA-----------", formData);
    const { data, error } = await login({
      variables: {
        username: formData.username,
        password: formData.password,
      },
    });
    if (error) {
      console.log("Error", error, data);
      setErrorMsg(
        "Incorrect username or password. Or, your account is not activated. Or, this username does not exist. Please try again."
      );
      setTimeout("");
    } else {
        localStorage.setItem("token", data.tokenAuth.token);
        localStorage.setItem("refreshToken", data.tokenAuth.refreshToken);
        localStorage.setItem("username", data.tokenAuth.payload.username);
        localStorage.setItem("api_key", data.tokenAuth.payload.api_key);
        localStorage.setItem("autoLogin", data.tokenAuth.payload.autoLogin);
        client.setHeader("Authorization", `JWT ${data.tokenAuth.token}`);
        if (nextUrl && nextUrl.get("next")) {
          navigate(`${nextUrl.get("next")}`);
        } else {
          navigate("/cul/assets-list-full");
        }
    }
  };

  return (
    <Grid>
          <title>APIE | Signin</title>

          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
            {mobileView && (
              <Card className={classes.cardMobile}>
                <CardContent className={classes.cardContentMobile}>
                  <Box alignItems="center" mb={3} flexGrow={1}>
                    <Box
                      flexGrow={1}
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <div>
                        <Typography gutterBottom variant="subtitle2">
                          Sign in
                        </Typography>
                      </div>
                    </Box>
                    <Typography variant="body2">{ welcome_page_text }</Typography>
                  </Box>
                  <Box flexGrow={1} mb={1}>
                    <TextField
                      className={classes.textFieldStyleMobile}
                      fullWidth
                      //autoFocus
                      label="Username" // Address"
                      margin="normal"
                      name="username"
                      type="username"
                      variant="outlined"
                      id="username"
                      {...register("username", {
                        required: true,
                      })}
                    />
                    {errors.username && errors.username.type === "required" && (
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Username cannot be blank.
                      </FormHelperText>
                    )}
                    <TextField
                      className={classes.textFieldStyleMobile}
                      fullWidth
                      label="Password"
                      margin="normal"
                      name="password"
                      type="password"
                      variant="outlined"
                      id="password"
                      {...register("password", { required: true })}
                    />
                    {errors.password && errors.password.type === "required" && (
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Password cannot be blank.
                      </FormHelperText>
                    )}

                    {errorMsg && (
                      <Box mt={1} mb={1} ml={2}>
                        <FormHelperText className={classes.errorStyleMobile} error>
                          {errorMsg}
                        </FormHelperText>
                      </Box>
                    )}
                    {successMsg && (
                      <Box mt={2} className={classes.successStyleMobile}>
                        {successMsg}
                      </Box>
                    )}
                    <Box mt={5} mb={1} display="flex" justifyContent="center">
                      <Button
                        className={classes.buttonStyle}
                        color="primary"
                        type="submit"
                        variant="contained"
                      >
                        Sign In
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            )}
            {tabletView && (
              <Container className={classes.cardContainerTablet} maxWidth="sm">
                <Card className={classes.cardTablet}>
                  <CardContent className={classes.cardContentTablet}>
                    <Box alignItems="center" mb={3} flexGrow={1}>
                      <Box
                        flexGrow={1}
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <div>
                          <Typography gutterBottom variant="subtitle1">
                            Sign in
                          </Typography>
                        </div>
                      </Box>
                      <Typography variant="body1">{ welcome_page_text }</Typography>
                    </Box>
                    <Box flexGrow={1} mb={1}>
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        //autoFocus
                        label="Username" // Address"
                        margin="normal"
                        name="username"
                        type="username"
                        variant="outlined"
                        id="username"
                        {...register("username", {
                          required: true,
                        })}
                      />
                      {errors.username && errors.username.type === "required" && (
                        <FormHelperText className={classes.errorStyleMobile} error>
                          Username cannot be blank.
                        </FormHelperText>
                      )}
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                        type="password"
                        variant="outlined"
                        id="password"
                        {...register("password", { required: true })}
                      />
                      {errors.password && errors.password.type === "required" && (
                        <FormHelperText className={classes.errorStyleMobile} error>
                          Password cannot be blank.
                        </FormHelperText>
                      )}

                      {errorMsg && (
                        <Box mt={1} mb={1} ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            {errorMsg}
                          </FormHelperText>
                        </Box>
                      )}
                      {successMsg && (
                        <Box mt={2} className={classes.successStyle}>
                          {successMsg}
                        </Box>
                      )}
                      <Box mt={5} mb={1} display="flex" justifyContent="center">
                        <Button
                          className={classes.buttonStyle}
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          Sign In
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Container>
            )}
            {!mobileView && !tabletView && (
              <Container className={classes.cardContainer} maxWidth="sm">
                <Card style={{ display: "flex", flexDirection: "row" }}>
                  <CardContent className={classes.cardContent}>
                    <Box alignItems="center" mb={3} flexGrow={1}>
                      <Box mb={2}>
                      </Box>
                      <Typography variant="body1">{welcome_page_text}</Typography>
                    </Box>
                  </CardContent>
                  <Divider orientation="vertical" flexItem />
                  <CardContent className={classes.cardContent} style={{ minWidth: 350 }}>
                    <Box
                      flexGrow={1}
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography gutterBottom variant="subtitle1">
                        Sign in
                      </Typography>
                    </Box>

                    <Box flexGrow={1} mb={1}>
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        //autoFocus
                        label="Username" // Address"
                        margin="normal"
                        name="username"
                        variant="outlined"
                        id="username"
                        {...register("username", {
                          required: true,
                        })}
                      />
                      {errors.username && errors.username.type === "required" && (
                        <FormHelperText className={classes.errorStyleMobile} error>
                          Username cannot be blank.
                        </FormHelperText>
                      )}
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        label="Password"
                        margin="normal"
                        name="password"
                        type="password"
                        variant="outlined"
                        id="password"
                        {...register("password", { required: true })}
                      />
                      {errors.password && errors.password.type === "required" && (
                        <FormHelperText className={classes.errorStyleMobile} error>
                          Password cannot be blank.
                        </FormHelperText>
                      )}
                      {errorMsg && (
                        <Box mt={1} mb={1} ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            {errorMsg}
                          </FormHelperText>
                        </Box>
                      )}
                      {successMsg && (
                        <Box mt={2} className={classes.successStyle}>
                          {successMsg}
                        </Box>
                      )}
                      <Box mt={5} display="flex" justifyContent="center">
                        <Button
                          className={classes.buttonStyle}
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          Sign In
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Container>
            )}
          </form>
    </Grid>
  );
};

export default SignIn;
