import React, { useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
  Button,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import update from "immutability-helper";
var findAnd = require("find-and");

const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#0D47A1",
    height: "34px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
}));

export default function AttachmentsDialog({
  attachmentsList,
  closeDialog,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog();
  };

  return (
    <div style={{ width: "100%", minWidth: 500 }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "500px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={11}
              style={{
                textAlign: "center",
                paddingLeft: 15,
              }}
            >
              Attachments
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {attachmentsList.map((item, index) => (
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <Grid item xs={12}>
                  {item}
                </Grid>
              </Grid>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              style={{ marginRight: 5 }}
              onClick={handleClose}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
