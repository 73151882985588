const AssetList_query = `
    query( $setName : String)
    {
      allAssets(setName: $setName)
      {
          assetId
          setName
          assetFilename
          filenamePath
          bfAssetKey
          section
          assetSubtype
          attachment
          dateUploaded
          statusUpload
          assetName
          assetDesc
          assetLabels
          assetApproved
          cfProductName
          cfProductId
          cfPartNo
          cfChannels
          cfPromoName
          cfAssetVendorRef
          statusAsset
          logActivity
          createDate
          createBy
          updateDate
          updateBy

      }
    }
`;

const UploadAsset_mutation = `mutation processData(
    $apiKey: String!
    $assetName: String!
    $assetDescription: String!
    $sectionName: String!
    $assetFileName: String!
    $assetSetSrvPath: String!
    $attachments: [String!]!
    $labels: [String!]!
    $customFieldVals: JSONString!
    ){
      processData(
      apiKey: $apiKey,
      assetName: $assetName,
      assetDescription: $assetDescription,
      sectionName: $sectionName,
      assetFileName: $assetFileName,
      assetSetSrvPath:$assetSetSrvPath,
      attachments: $attachments
      labels: $labels,
      customFieldVals: $customFieldVals
      ){
        assetKeyResult
        assetStatusResult
      }
    }
`;

const SetAssetKeyApproved_mutation = `mutation setAssetKeyApproved(
  $assetId: Int!
  $assetKey: String!
  $assetApproved: Boolean!
  ){
    setAssetKeyApproved(
    assetId: $assetId,
    assetKey: $assetKey,
    assetApproved: $assetApproved,
    ){
      asset{
        assetId
        bfAssetKey
        assetApproved
        statusUpload
        dateUploaded
      }
    }
  }
`;

module.exports = {
  ASSET_LIST_QUERY: AssetList_query,
  UPLOAD_ASSET_MUTATION: UploadAsset_mutation,
  Set_Asset_Key_Approved_MUTATION: SetAssetKeyApproved_mutation,
};
