import React, { useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
  Button,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#0D47A1",
    height: "34px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#0D47A1",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#0D47A1",
    },
    "&:hover": {
      backgroundColor: "#1E88E5",
    },
  },
}));

export default function LabelsTab({ labelsList, closeDialog }) {
  const classes = useStyles();
  return (
    <div style={{ width: "100%", minWidth: 500, minHeight:220 }}>
      {labelsList.map((item, index) => (
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <Grid item xs={12}>
            {item}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
