import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Divider,
  Button,
  IconButton,
  Drawer,
  DialogTitle,
  Link,
  Box,
  MenuItem,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import CancelIcon from "@mui/icons-material/Cancel";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

//import logoImg from "../assets/logo.png";
const headerData = [
  {
    label: "Faster",
    href: "#",
  },
  {
    label: "Higher",
    href: "#",
  },
  {
    label: "Stronger",
    href: "#",
  },
  {
    label: "Together",
    href: "#",
  },
];
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 160,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 16,
      "& .MuiSvgIcon-root": {
        fontSize: 24,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFEFE",
    color: "black",
    height: "80px",
    justifyContent: "center",
    paddingRight: "10px",
    paddingLeft: "118px",
    // "@media (max-width: 963px)": {
    //   //900
    //   paddingLeft: 0,
    //   paddingRight: 0,
    // },
  },
  logo: {
    textAlign: "left",
    height: "58px",
  },
  menuButton: {
    fontFamily: "Roboto, Open Sans, sans-serif",
    fontWeight: 600,
    fontSize: "20px",
    marginRight: "20px",
    height: "50 !important",
  },
  menuItem1: {
    "& .MuiMenuItem-root": {
      fontSize: 16,
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        marginRight: 12,
      },
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "0px 30px",
  },
  buttonStyle: {
    borderRadius: 40,
    paddingLeft: 20,
    paddingRight: 10,
    textTransform: "none",
    backgroundColor: "#F7F4F7",
    "& .MuiButton-startIcon": {
      marginRight: 3,
    },
    "& .MuiButton-endIcon": {
      marginRight: 3,
    },
  },
  signUpButtonStyle: {
    borderRadius: 4,
    width: "99px !important",
    height: "42px",
    textTransform: "none",
    marginBottom: 25,
    "& .MuiButton-label": {
      fontFamily: "Roboto, Open Sans, sans-serif",
    },
    "&:active": {
      backgroundColor: "#6A1B9A",
    },
    "&:hover": {
      backgroundColor: "#6A1B9A",
    },
  },
  "MuiButton-label": {
    fontFamily: "Roboto, Open Sans, sans-serif",
  },
  mobileSignUpButtonStyle: {
    borderRadius: 4,
    fontFamily: "Roboto, Open Sans, sans-serif",
    width: "107px !important",
    height: "42px",
    textTransform: "none",
    marginTop: 15,
    marginLeft: 40,
    "&:active": {
      backgroundColor: "#6A1B9A",
    },
    "&:hover": {
      backgroundColor: "#6A1B9A",
    },
  },
  drawerTitle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
  },
}));

export default function Header() {
  const {
    header,
    logo,
    menuButton,
    toolbar,
    drawerContainer,
    buttonStyle,
    drawerTitle,
    menuItem1,
  } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  /// Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  ///
  const { mobileView, drawerOpen } = state;
  let username = localStorage.getItem("username")
    ? localStorage.getItem("username")
    : null;

  useEffect(() => {
    setAnchorEl(null);
  }, [window.innerWidth]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1680 //(RTS-1380)1310 //900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <div style={{ marginTop: 25,display: "flex", width: "100%" }}>
          {getMenuButtons()}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <Box alignItems="center" flexGrow={1}></Box>
        <IconButton
          {...{
            edge: "end",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon style={{ marginTop: 0 }} fontSize="28px" />
        </IconButton>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <DialogTitle className={drawerTitle}>
            <IconButton onClick={handleDrawerClose}>
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    let data = headerData;

    return (
      <>
        {username && (
        <>
          <Link
            {...{
              component: RouterLink,
              to: "",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "profile",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem>Hi {username}</MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/cul/signout",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "signout",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem>Sign out</MenuItem>
          </Link>
        </>)}
        <Divider sx={{ my: 0.5 }} />
        {data.map(({ label, href }) => (
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem>{label}</MenuItem>
          </Link>
        ))}
      </>
    );
  };

  const getMenuButtons = () => {
    let data = headerData;
    return (
      <>
        <Box
          style={{
            display: "inline-flex",
            flexDirection: "row",
            width: "50%",
            paddingLeft: 290,
          }}
        >
          {data.map(({ label, href }) => (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                style: { textTransform: "none", marginBottom: 25 },
                className: menuButton,
              }}
            >
              {label}
            </Button>
          ))}
        </Box>
        {username && (
        <>
        <Box
          style={{
            display: "inline-flex",
            flexDirection: "row",
            paddingTop:10
          }}
        >
          <PersonOutlineIcon style={{paddingTop:2}}/>
          <Typography variant="body1">Hi {username}</Typography>
        </Box>
        <Box ml={2} mb={2} display="flex" alignItems="center">
          <Link
            component={RouterLink}
            to="/cul/signout"
            variant="h2"
            color="primary"
          >
            Sign out
          </Link>
        </Box>
        </>)}
      </>
    );
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
